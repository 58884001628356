<template>
    <section class="section hero is-fullheight">
    <div>
        <notification class="is-info">
          <div>
            <b-icon icon="buffer" custom-size="default" />
            <b>Old Machine : Link <a target="_blank" href="https://biller.ssiplengg.com">https://biller.ssiplengg.com</a></b>&nbsp;
            <b>New Machine : Link <a target="_blank" href="https://newbiller.ssiplengg.com">https://newbiller.ssiplengg.com</a></b>
          </div>
        </notification>
        <div class="columns is-centered">
          <div class="column is-4">
            <div class="card has-card-header-background">
              <header class="card-header">
                <div class="card-header-title">
                  <span class="icon">
                    <i class="mdi mdi-lock default"></i>
                  </span>
                  <span>Generate Bill</span>
                </div>
              </header>
              <div class="card-content is-vcentered">
                <div class="level">
                  <div class="level-item has-text-centered">
                    <div>
                      <b-icon
                          icon="view-dashboard"
                          size="is-large"
                          type="is-primary">
                      </b-icon>
                    </div>
                  </div>
                </div>
                <form @submit.stop.prevent="onSubmit">
                  <b-field
                    class="mt-4 mb-4"
                    :type="validateState('emp_num')"
                    :message="{
                        'The emp num is unknown': $v.form['emp_num'].$anyError
                    }">
                      <b-input
                        id="emp_num"
                        v-model="$v.form.emp_num.$model"
                        placeholder="User Employee Number"
                        required
                      />
                  </b-field>
                  <footer class="card-footer">
                    <b-button :disabled="$v.form['emp_num'].$anyError" type="is-primary" native-type="submit" rounded class="card-footer-item">
                      <i class="mdi mdi-arrow-right default"></i> Proceed
                    </b-button>
                  </footer>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import Notification from '@/components/Notification'
import { apiService } from '@/services'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import router from '@/router'
export default {
  name: 'Biller',
  mixins: [validationMixin],
  components: {
    Notification
  },
  data () {
    return {
      form: {
        emp_num: '',
        submitted: false
      }
    }
  },
  validations: {
    form: {
      emp_num: {
        required,
        async isUnique (value) {
          if (value === '') return true
          if (value.length > 2) {
            const response = await apiService.postAll('/exists/user', {
              emp_num: value
            }).catch(error => this.showError(error))
            if (response) {
              return Boolean(await response.data)
            } else {
              return false
            }
          } else {
            return false
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    validateState (name) {
      const { $dirty, $anyError } = this.$v.form[name]
      if ($dirty) {
        return { 'is-danger': $anyError, 'is-link': !$anyError }
      } else {
        return { 'is-danger': false, 'is-link': true }
      }
    },
    onSubmit () {
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        router.push({ name: 'usermeters', params: { id: this.form.emp_num } })
      }
    }
  }
}
</script>
